import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import '../assets/TabsNavigation.css';

const TabsNavigation = ({ cityData }) => {
  const location = useLocation();

  // Desired order of cities
  const cityOrder = ['general','jayapura', 'timika', 'manokwari', 'cibitung', 'cikarang'];

  // Sort cityData based on the desired order
  const sortedCityData = cityData.sort((a, b) => {
    return cityOrder.indexOf(a.name.toLowerCase()) - cityOrder.indexOf(b.name.toLowerCase());
  });

  // Extract only the base path without "/update"
  const basePath = location.pathname.split('/')[1];

  // Ensure we don't highlight a tab when on edit page
  const currentTab = sortedCityData.map(city => city.name.toLowerCase()).includes(basePath) ? `/${basePath}` : false;

  return (
    <Tabs
      value={currentTab} // Only set the value if it's a valid city path
      variant="scrollable" // Enables scrollable behavior on small screens
      scrollButtons="auto" // Auto shows scroll buttons when necessary
      className="custom-tabs"
      TabIndicatorProps={{
        style: { backgroundColor: '#333' },  // Set active underline color here
      }}
    >
      {sortedCityData.map(city => (
        <Tab
          key={city.id}
          label={city.name.charAt(0).toUpperCase() + city.name.slice(1)} // Capitalize first letter
          component={Link}
          to={`/${city.name.toLowerCase()}`} // Ensure lowercase in URL
          value={`/${city.name.toLowerCase()}`} // Ensure lowercase in value comparison
          sx={{
            fontSize: { xs: '12px', sm: '14px', md: '16px' },
            fontWeight: 'bold',
            color: currentTab === `/${city.name.toLowerCase()}` ? 'white' : 'grey',
            '&.Mui-selected': {
              color: 'white',
              backgroundColor: '#333',
              borderRadius: '10px 10px 0 0',
            },
          }}
        />
      ))}

    </Tabs>
  );
};

export default TabsNavigation;
