import React, { useEffect, useState, useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';

// Register ArcElement so Pie chart can be used
Chart.register(ArcElement);

const PieProgress = ({ progressData }) => {
  const [aggregatedProgress, setAggregatedProgress] = useState(0);
  const progressRef = useRef(0);  // Create a ref to store the latest progress

  // Calculate the total progress whenever progressData changes
  useEffect(() => {
    const totalProgress = progressData.reduce((sum, item) => sum + item.progress, 0);
    const newAggregatedProgress = totalProgress / (progressData.length || 1); 
    setAggregatedProgress(newAggregatedProgress);
    progressRef.current = newAggregatedProgress; 
  }, [progressData]); 

  const data = {
    labels: ['Progress', 'Remaining'],
    datasets: [{
      data: [aggregatedProgress, 100 - aggregatedProgress], // Dynamic progress and remaining
      backgroundColor: (context) => {
        const chart = context.chart;
        const { ctx, chartArea } = chart;

        if (!chartArea) {
          return null;
        }

        // Create gradient based on chart dimensions
        const gradient = ctx.createLinearGradient(100, 200, chartArea.width, 50);
        gradient.addColorStop(0, 'green');    // Red at 0%
        gradient.addColorStop(0.5, 'yellow'); // Yellow at 50%
        gradient.addColorStop(1, 'red');   // Green at 100%

        return [gradient, '#555']; // Gradient for progress, #555 for remaining
      },
      hoverBorderWidth: 6, 
      borderColor: '#fff',
      cutout: '70%',  // This property makes the pie chart a donut chart (70% of the chart's radius is cut out)
    }]
  };

  // Plugin to add custom text to the center of the Donut chart
  const plugins = [{
    id: 'custom-text',
    beforeDraw: (chart) => {
      const { width, height, ctx } = chart;
      ctx.restore();
      const fontSize = (height / 160).toFixed(2);  // Dynamically adjust the font size
      ctx.font = `bold ${fontSize}em sans-serif`;  // Make the text bold
      ctx.textBaseline = 'middle';
      ctx.fillStyle = 'white';  // Set the text color to white

      const text = Number.isInteger(progressRef.current) 
        ? `${progressRef.current}%`          // If the number is an integer, show it without decimals
        : `${progressRef.current.toFixed(2)}%`;  
        
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;

      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }];

  return (
    <div 
      style={{
        display: 'flex',           // Flexbox for centering
        justifyContent: 'center',   // Center horizontally
        alignItems: 'center',       // Center vertically
        padding: '20px',            // Keep your padding
        height: '20vh',            // Full viewport height (or adjust as needed)
        width: '100%'               // Full width of the parent container
      }}
    >
    <Pie 
      data={data} 
      width={150}    // Width of the chart
      height={150}   // Height of the chart
      plugins={plugins}  // Add custom plugins for center text (if any)
    />
  </div>
  );
}

export default PieProgress;
