import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import TabsNavigation from './components/page/TabsNavigation';
import Dashboard from './components/page/Dashboard';
import EditPage from './components/page/EditPage'; // Import EditPage component
import './App.css';
import BDI from './assets/bdi.png';
import CircularProgress from '@mui/material/CircularProgress'; // Assuming you're using Material UI
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import GeneralPage from './components/page/GeneralPage'; // Import GeneralPage

function App() {
  const [cityData, setCityData] = useState([]); // Initialize state for city data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false); // New state to manage errors
  const [errorMessage, setErrorMessage] = useState(''); 

  const sixHoursInMilliseconds = 6 * 60 * 60 * 1000; // 6 jam dalam milidetik

  const checkAccessValidity = () => {
    const accessCode = localStorage.getItem('accessCode');
    const accessTime = localStorage.getItem('accessTime');

    if (accessCode && accessTime) {
      const currentTime = Date.now();
      const timeDifference = currentTime - parseInt(accessTime, 10);

      if (timeDifference > sixHoursInMilliseconds) {
        // Hapus jika lebih dari 6 jam
        localStorage.removeItem('accessCode');
        localStorage.removeItem('accessTime');
      } else {
        console.log("Access code is still valid.");
      }
    } else {
      console.log("No access code or access time found.");
    }
  };

  useEffect(() => {
    checkAccessValidity(); // Panggil saat komponen dimuat
  }, []);

  // Fetch city data from API
  useEffect(() => {
    const fetchCityData = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://executive.api.bignet.id/api/gateway');
        if (!response.ok) {
          throw new Error('Failed to fetch city data');
        }
        const result = await response.json();
        if (result.status) {
          setCityData(result.data); // Set the city data
        } else {
          setError(true);
          setErrorMessage('Error: Failed to load city data');
        }
      } catch (error) {
        console.error('Error fetching city data:', error);
        setError(true); // Set error state on failure
        setErrorMessage(`Error: ${error.message}`); // Set error message
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchCityData();
  }, []);

  // Dynamically generate routes for each city
  const cityRoutes = cityData.map(city => (
    <Route
      key={city.id}
      path={`/${city.name.toLowerCase()}`}  // Ensure paths are in lowercase
      element={city.name.toLowerCase() === 'general' ? <GeneralPage /> : <Dashboard locationData={city} cityName={city.name} />}
    />
  ));

  // Add routes for editing city data
  const editRoutes = cityData.map(city => (
    <Route path="/:cityName/update" element={<EditPage />} />
  ));

  const [currentDateTime, setCurrentDateTime] = useState('');

  useEffect(() => {
    const getFormattedDateTime = () => {
      const date = new Date();
      const utcTime = date.getTime() + date.getTimezoneOffset() * 60000;
      const wibOffset = 7 * 60 * 60 * 1000;
      const wibDate = new Date(utcTime + wibOffset);

      const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
      const months = [
        'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli',
        'Agustus', 'September', 'Oktober', 'November', 'Desember'
      ];

      const dayName = days[wibDate.getDay()];
      const day = wibDate.getDate();
      const monthName = months[wibDate.getMonth()];
      const year = wibDate.getFullYear();
      const hours = String(wibDate.getHours()).padStart(2, '0');
      const minutes = String(wibDate.getMinutes()).padStart(2, '0');

      return `${dayName}, ${day} ${monthName} ${year}, ${hours}:${minutes} WIB`;
    };

    const updateDateTime = () => {
      setCurrentDateTime(getFormattedDateTime());
    };

    updateDateTime();
    const intervalId = setInterval(updateDateTime, 60000);

    return () => clearInterval(intervalId);
  }, []);

  // Handle rendering based on loading, error, and data states
  if (loading) {
    // Centered Loading Spinner
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress style={{ color: 'white' }} />
      </div>
    );
  }

  if (error) {
    // Centered Error Message with Icon and 2 rows of text
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column', textAlign: 'center' }}>
        <ErrorOutlineIcon style={{ fontSize: '48px', color: 'red' }} />
        <p style={{ margin: '10px 0', fontSize: '18px' }}>An error occurred</p>
        <p style={{ margin: '0', fontSize: '14px' }}>{errorMessage || 'Error fetching city data. Please try again later.'}</p>
      </div>
    );
  }

  return (
    <Router>
      <div className="App">
        <header className="header-container">
          <div className="logo-container d-flex">
            <img src={BDI} alt="Logo" className="logo-image" />
            <div className='d-flex flex-column' style={{ gap: '2px' }}>
              <h1 className='logo-title'>DASHBOARD</h1>
              <h5 className='logo-subtitle'>IP-HUB OPEX - PROGRESS REPORT</h5>
            </div>
          </div>
          <div className="date-container">
            <p className="date-header">{currentDateTime}</p>
          </div>
        </header>

        <div className="content-container">
          <TabsNavigation cityData={cityData} />
          
          {/* Scrollable content area */}
          <div className="scrollable-content">
            <Routes>
              <Route path="/" element={<Navigate to="/manokwari" replace />} />
              {cityRoutes}
              {editRoutes}
            </Routes>
          </div>
        </div>
        <footer style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '30px',
          width: '100%',        // Ensure the footer takes full width
          textAlign: 'center',  
          marginTop:'-70px',  
        }}>
          <p style={{ fontSize: '10px', margin: '0' }}>Copyright © 2024 BIGNET | IT Application Engineering Department.</p>
        </footer>

      </div>
    </Router>
  );
}

export default App;
