import React, { useState, useEffect } from 'react';
import { IconButton, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { useNavigate } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import '../assets/Dashboard.css';
import PieProgress from './PieProgress';
import { styled } from '@mui/system';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const CenteredContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
});

const Dashboard = ({ cityName }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [ipHubData, setIpHubData] = useState([]);
  const [ipCoreData, setIpCoreData] = useState([]);
  const [ossData, setOssData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [remarksData, setRemarksData] = useState([]);  // State untuk remarks per kategori
  const [loading, setLoading] = useState(true);
  const [statusData, setStatusData] = useState([]);  // State untuk status data
  const [open, setOpen] = useState(false);  // Modal open state
  const [code, setCode] = useState('');  // Code input state
  const [errorCode, setErrorCode] = useState(false);
  const navigate = useNavigate();
  
  const ipHubCoreOrder = ['PO', 'IMPORT', 'DELIVERY', 'INSTALLATION'];
  const otherOrder = ['PO Installation Material', 'PO Measurement Tools', 'TRAINING', 'INTEGRATION & INTERNAL ATP', 'ACCEPTANCE BY BAKTI', 'BAST & INTEGRATION CERTIFICATE'];
  const ossOrder = ['Purchase  Third Party Component', 'Backend Development', 'Backend Production', 'Front-End Development', 'Front-End Production', 'Front-End & Backend Integration'];
  
  // Fetch data dari API termasuk remarks berdasarkan kategori dan gateway
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const city = cityName.toLowerCase();

        const fetchWithErrorHandling = async (url) => {
          const response = await fetch(url, { cache: 'no-store' });
          if (!response.ok) {
            throw new Error(`Failed to fetch data from ${url}: ${response.status}`);
          }
          return await response.json();
        };

        const [ipHubRes, ipCoreRes, otherRes, remarksRes, statusRes, ossRes] = await Promise.all([
          fetchWithErrorHandling(`https://executive.api.bignet.id/api/iphub`),
          fetchWithErrorHandling(`https://executive.api.bignet.id/api/ipcore`),
          fetchWithErrorHandling(`https://executive.api.bignet.id/api/other`),
          fetchWithErrorHandling(`https://executive.api.bignet.id/api/remark`),  // Fetch remarks per category and gateway
          fetchWithErrorHandling(`https://executive.api.bignet.id/api/status`),
          fetchWithErrorHandling(`https://executive.api.bignet.id/api/sso`), 
        ]);

        const sortDataByProgressName = (data, order) => {
          if (!data || !Array.isArray(data)) return [];
          return data
            .filter(
              (item) =>
                item.gateway &&
                item.gateway.name &&
                item.gateway.name.toLowerCase() === city
            )
            .sort((a, b) => order.indexOf(a.progress_name) - order.indexOf(b.progress_name));
        };

        const ipHubSortedData = sortDataByProgressName(ipHubRes.data, ipHubCoreOrder);
        const ipCoreSortedData = sortDataByProgressName(ipCoreRes.data, ipHubCoreOrder);
        const ossSortedData = sortDataByProgressName(ossRes.data, ossOrder);
        let additionalSortedData = sortDataByProgressName(otherRes.data, otherOrder);

        // Set remarks per kategori
        const cityRemarks = remarksRes.data.filter(
          (item) =>
            item.gateway &&
            item.gateway.name.toLowerCase() === city
        );

        // Set the sorted data and remarks
        setIpHubData(ipHubSortedData);
        setIpCoreData(ipCoreSortedData);
        setOssData(ossSortedData);
        setAdditionalData(additionalSortedData);
        setRemarksData(cityRemarks);  // Simpan remarks berdasarkan kategori

        // Set status data for the city
        const cityStatus = statusRes.data?.filter(
          (item) =>
            item.gateway &&
            item.gateway.name.toLowerCase() === city
        );
        setStatusData(cityStatus || []);

        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        alert(`Error fetching data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [cityName]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleEditClick = () => {
    navigate(`/${cityName.toLowerCase()}/update`);
  };
  
  const handleOpenModal = () => {
    // Periksa apakah token sudah ada di localStorage dan apakah masih valid
    const storedCode = localStorage.getItem('accessCode');
    const storedTime = localStorage.getItem('accessTime');
  
    if (storedCode && storedTime) {
      const currentTime = Date.now();
      const expirationTime = 6 * 60 * 60 * 1000; // 6 jam dalam milidetik
  
      if (currentTime - storedTime < expirationTime) {
        // Token masih valid, langsung navigasi tanpa membuka modal
        handleEditClick();
        return;
      } else {
        // Token sudah kadaluarsa, hapus dari localStorage
        localStorage.removeItem('accessCode');
        localStorage.removeItem('accessTime');
      }
    }
  
    // Jika token tidak ada atau sudah kadaluarsa, buka modal
    setOpen(true);
  };
  
  const handleCloseModal = () => {
    setOpen(false);
    setCode('');
  };
  
  const handleConfirm = () => {
    const correctCode = 'RpGuDJLhM3Hn7vxw6P8S2BqAa9VJ-BDI';  // Kode akses yang benar
  
    if (code === correctCode) {
      // Simpan kode dan waktu ke localStorage
      localStorage.setItem('accessCode', 'RzzGudDJjLLhM3H97vxXw6P81iIlS2BqAa9VJ-Joko');
      localStorage.setItem('accessTime', Date.now()); // Simpan waktu dalam milidetik
  
      // Langsung navigasi
      handleEditClick();
    } else {
      setErrorCode(true);
    }
  };

  const getRemarksForCategory = (categoryName) => {
    const categoryRemarks = remarksData.find(
      (remark) => remark.category.name.toLowerCase() === categoryName.toLowerCase()
    );
    return categoryRemarks ? categoryRemarks.description : 'No remarks available';
  };


  let sections = [
    { title: 'IP HUB', key: 'ipHub', data: ipHubData, category: 'iphub' },
    { title: 'IP CORE', key: 'ipCore', data: ipCoreData, category: 'ipcore' },
    { title: 'OSS', key: 'oss', data: ossData, category: 'oss' },
    { title: 'OTHERS', key: 'additional', data: additionalData, category: 'other' },
  ];
  
  // Sorting logic for "OTHERS" section based on otherOrder array
  sections.forEach((section) => {
    if (section.key === 'additional') {
      section.data = section.data.sort((a, b) => {
        const orderA = otherOrder.indexOf(a.progress_name);
        const orderB = otherOrder.indexOf(b.progress_name);
        
        // Handle case where progress_name is not in otherOrder
        if (orderA === -1) return 1;
        if (orderB === -1) return -1;
        
        return orderA - orderB;
      });
    }
  });

  const nameMapping = {
    'PO Installation Material': 'PO Installation Material',
    'PO Measurement Tools': 'PO Measurement Tools',
    'TRAINING': 'TRAINING',
    'ACCEPTANCE by BAKTI': 'ACCEPTANCE BY BAKTI',
    'INTEGRATION & INTERNAL APP': 'INTEGRATION & INTERNAL ATP',
    'BAST': 'BAST & INTEGRATION CERTIFICATE'
  };
  
  const getDisplayName = (originalName) => {
    return nameMapping[originalName] || originalName; // Return the mapped name or the original if not found
  };
  

  if (cityName.toLowerCase() === 'cikarang') {
    sections = sections.filter(section => section.key === 'ipCore');
  }

  const renderStatusSection = () => (
    <div className="status-section">
      <h2 className='status-title'>STATUS</h2>
      {statusData.map((item, index) => (
        <div key={index} className="status-item">
          <strong
            className={`status-label ${item.status === 1 
              ? 'status-on-road' 
              : item.status === 2 
              ? 'status-off-POTENTIALLY' 
              : 'status-off-road'}`}
          >
            {item.status === 1
              ? 'ON TRACK'
              : item.status === 2
              ? 'POTENTIALLY OFF TRACK'
              : 'OFF TRACK'}
          </strong>

          {/* Conditionally render item.note based on item.status */}
          {(item.status === 0 || item.status === 2 ) && (
            <div
              className="remarks-textarea"
              dangerouslySetInnerHTML={{ __html: item.note }}
              style={{
                maxHeight: '100px',
                minHeight: '100px',
                overflowY: 'auto',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '5px',
              }}
            />
          )}
        </div>
      ))}
      <hr />
    </div>
  );

  if (loading) {
    return (
      <CenteredContainer>
        <CircularProgress sx={{ color: 'white', marginTop: '10px' }} />
        <p>Loading data for {cityName}...</p>
      </CenteredContainer>
    );
  }

  const noData = ipHubData.length === 0 && ipCoreData.length === 0 && additionalData.length === 0 && statusData.length === 0;
  if (noData) {
    return (
      <div className="centered-container">
        <SentimentVeryDissatisfiedIcon style={{ fontSize: 60 }} />
        <p>No data available for {cityName}.</p>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <div className="city-header">
        <h1 className="city-title">{cityName}</h1>
        <IconButton onClick={handleOpenModal} >
          <EditNoteIcon className="edit-icon" />
        </IconButton>
      </div>
  
      {/* Render status section */}
      {renderStatusSection()}
  
      {sections.map(({ title, data, category }, index) => (
  <React.Fragment key={title}>
    {/* Title for the section */}
    <h2 className='progress-title'>
      {cityName.toLowerCase() === 'cibitung' && title.toLowerCase() === 'ip hub'
        ? 'CNMS'
        : title.toUpperCase()}
    </h2>


    {/* Row 1: Progress Bars and Pie Chart */}
    <div className="row-container">
      <div className="progress-bar-section">
      {isMobile && (
        <div className="pie-chart-section">
          <PieProgress progressData={data} />
        </div>
        )
      }
        {data.map((item, idx) => (
          <ProgressBar
            key={idx}
            title={getDisplayName(item.progress_name)}
            progress={item.progress}
            dueDate={item.due_date}
            lastUpdate={item.UpdatedAt}
          />
        ))}
      </div>
      {!isMobile && (
        <div className="pie-chart-section">
          <PieProgress progressData={data} />
        </div>
        )
      }
    </div>

    {/* Row 2: Remarks Section */}
    <div className="remarks-container">
      <div className="remarks-section">
        <h4 className='remark-title'>REMARKS {title.toUpperCase()}</h4>
        <div
          className="remarks-textarea"
          dangerouslySetInnerHTML={{ __html: getRemarksForCategory(category) }}
          style={{
            maxHeight: '500px',
            minHeight: '100px',
            overflowY: 'auto',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            backgroundColor: '#333',
            color: 'white',
          }}
        />
      </div>
    </div>
    {(index === 0 || index === 1 || index === 2) && <hr />}
  </React.Fragment>
))}

  
      {/* Modal for code input */}
      <>
        {errorCode && (
          <Alert
            variant="filled"
            severity="error"
            onClose={() => setErrorCode(false)}
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 9999,
            }}
          >
            <AlertTitle>Error</AlertTitle>
            Incorrect code, please try again.
          </Alert>
        )}
  
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          maxWidth="sm"
          fullWidth
          sx={{ '& .MuiDialog-paper': { width: '400px', maxWidth: 'none' } }}
        >
          <DialogTitle sx={{ backgroundColor: '#333' }}>Enter Access Code</DialogTitle>
          <DialogContent sx={{ backgroundColor: '#333' }}>
            <TextField
              autoFocus
              margin="dense"
              label="Code"
              type="password"
              fullWidth
              variant="standard"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              InputLabelProps={{
                style: { color: 'white' },
              }}
              InputProps={{
                style: { color: 'white' },
              }}
              sx={{
                '& .MuiInput-underline:before': {
                  borderBottomColor: 'white',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: 'white',
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                  borderBottomColor: 'white',
                },
              }}
            />
          </DialogContent>
          <DialogActions sx={{ backgroundColor: '#333' }}>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'rgba(255, 0, 0, 0.2)', color: 'red', fontWeight: 'bold' }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'rgba(255, 255, 0, 0.2)', color: 'yellow', fontWeight: 'bold' }}
              onClick={() => {
                if (code === 'RpGuDJLhM3Hn7vxw6P8S2BqAa9VJ-BDI') {
                  localStorage.setItem('accessCode', 'RzzGudDJjLLhM3H97vxXw6P81iIlS2BqAa9VJ-Joko');
                  localStorage.setItem('accessTime', Date.now());
                  setOpen(false);
                  navigate(`/${cityName.toLowerCase()}/update`);
                } else {
                  setErrorCode(true);
                }
              }}
            >
              Enter
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </div>
  );
};

export default Dashboard;
