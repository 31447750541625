import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CircularProgress, IconButton, TextField, Slider, Button, Select, MenuItem } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import ReactQuill , { Quill }  from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../assets/edit.css';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import LoadingButton from '@mui/lab/LoadingButton'; 
import SaveIcon from '@mui/icons-material/Save'; 
import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize);

const CenteredContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
});

const GradientSlider = styled(Slider)({
  color: 'transparent',
  height: 8,
  '& .MuiSlider-track': {
    background: 'linear-gradient(to right, red, orange, yellow, green)',
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0.3,
    backgroundColor: '#bfbfbf',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
  },
});


const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      [{ color: [] }, { background: [] }], // This is for text color and background color
      ['clean'],  // Remove formatting button
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
      }
  };
  
  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color', 'background', // Add color and background here
  ];

const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const EditPage = () => {
  const { cityName } = useParams();
  const navigate = useNavigate();
  const [statusData, setStatusData] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingSection, setLoadingSection] = useState({});
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [cityData, setCityData] = useState({
    ipHub: [],
    ipCore: [],
    OTHERS: [],
    sso: [],
    remarks: {
      ipHub: '',
      ipCore: '',
      OTHERS: '',
      sso: '',
    },
  });

  useEffect(() => {
    const token = localStorage.getItem('accessCode');
    if (!token) {
      navigate(-1);
    }
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClose = () => {
    navigate(`/${cityName.toLowerCase()}`);
  };

  const fetchCityData = async () => {
    setLoading(true); 
    try {
      const [iphubRes, ipcoreRes, ssoRes, otherRes, remarksRes, statusRes] = await Promise.all([
        fetch('https://executive.api.bignet.id/api/iphub', { cache: 'no-store' }).then((res) => res.json()),
        fetch('https://executive.api.bignet.id/api/ipcore', { cache: 'no-store' }).then((res) => res.json()),
        fetch('https://executive.api.bignet.id/api/sso', { cache: 'no-store' }).then((res) => res.json()),
        fetch('https://executive.api.bignet.id/api/other', { cache: 'no-store' }).then((res) => res.json()),
        fetch('https://executive.api.bignet.id/api/remark', { cache: 'no-store' }).then((res) => res.json()),
        fetch('https://executive.api.bignet.id/api/status', { cache: 'no-store' }).then((res) => res.json()),
      ]);
  
      const filterByCityName = (data) =>
        data.filter(
          (item) =>
            item?.gateway?.name?.toLowerCase() === cityName.toLowerCase()
        );
  
      const filteredRemarks = remarksRes.data.filter(item => 
        item?.gateway?.name?.toLowerCase() === cityName.toLowerCase()
      );
  
      const filteredData = {
        ipHub: filterByCityName(iphubRes.data),
        ipCore: filterByCityName(ipcoreRes.data),
        OTHERS: filterByCityName(otherRes.data),
        sso: filterByCityName(ssoRes.data),
        remarks: {
          ipHub: filteredRemarks.find(item => item?.category?.name === 'iphub') || { description: 'No remarks available' },
          ipCore: filteredRemarks.find(item => item?.category?.name === 'ipcore') || { description: 'No remarks available' },
          OTHERS: filteredRemarks.find(item => item?.category?.name === 'other') || { description: 'No remarks available' },
          sso: filteredRemarks.find(item => item?.category?.name === 'oss') || { description: 'No remarks available' },
        },
      };      
  
      const filteredStatusData = filterByCityName(statusRes.data);
  
      setCityData(filteredData);
      setStatusData(filteredStatusData);
    } catch (error) {
      console.error('Error fetching city data:', error);
    } finally {
      setLoading(false);
    }
  };  

  useEffect(() => {
    fetchCityData();
  }, [cityName]);

  const handleSliderChange = (section, index, newValue) => {
    const updatedCityData = { ...cityData };
    updatedCityData[section][index].progress = newValue;
    setCityData(updatedCityData);
  };

  const handleTextFieldChange = (section, index, e) => {
    const updatedCityData = { ...cityData };
    const newValue = e.target.value === '' ? 0 : parseInt(e.target.value, 10);
    if (!isNaN(newValue)) {
      updatedCityData[section][index].progress = newValue;
      setCityData(updatedCityData);
    }
  };

  const handleRemarksChange = (section, newRemarks) => {
    const updatedCityData = { ...cityData };
    updatedCityData.remarks[section].description = newRemarks;
    setCityData(updatedCityData);
  };

  const handleDueDateChange = (section, index, newDueDate) => {
    const updatedCityData = { ...cityData };
    updatedCityData[section][index].due_date = newDueDate;
    setCityData(updatedCityData);
  };

  const handleSaveSection = async (sectionKey, apiEndpoint) => {
    setLoadingSection(prev => ({ ...prev, [sectionKey]: true }));
  
    const remark = cityData.remarks[sectionKey]; // Get the entire remark object
  
    if (!remark || !remark.id) {
      setErrorMessage(`Remark ID for ${sectionKey} not found`);
      setLoadingSection(prev => ({ ...prev, [sectionKey]: false }));
      return;
    }
  
    const updatedItems = cityData[sectionKey].map(item => ({
      id: item.id,
      progress: item.progress,
      due_date: item.due_date,
    }));
  
    try {
      await Promise.all(
        updatedItems.map(item =>
          fetch(`https://executive.api.bignet.id/api/${apiEndpoint}/${item.id}`, {
            method: 'PUT',
            cache: 'no-store',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              progress: item.progress,
              due_date: item.due_date,
            }),
          })
        )
      );
  
      await fetch(`https://executive.api.bignet.id/api/remark/${remark.id}`, {
        method: 'PUT',
        cache: 'no-store',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          description: remark.description,
        }),
      });
  
      setSuccessMessage(`Updated ${sectionKey.toUpperCase()} successfully`);
      setTimeout(() => {
        setSuccessMessage('');
        setLoadingSection(prev => ({ ...prev, [sectionKey]: false }));
      }, 5000);
    } catch (error) {
      setErrorMessage(`Failed to update ${sectionKey}`);
      setTimeout(() => {
        setErrorMessage('');
        setLoadingSection(prev => ({ ...prev, [sectionKey]: false }));
      }, 5000);
    }
  };
  

  const handleNoteChange = (index, newNote) => {
    const updatedStatusData = [...statusData];
    updatedStatusData[index].note = newNote;
    setStatusData(updatedStatusData);
  };


  const handleStatusChange = (index, newStatus) => {
    const updatedStatusData = [...statusData];
    updatedStatusData[index].status = newStatus;
    setStatusData(updatedStatusData);
  };

const handleSaveStatus = async () => {
  setLoadingStatus(true);
  try {
    await Promise.all(
      statusData.map((item) =>
        fetch(`https://executive.api.bignet.id/api/status/${item.id}`, {
          method: 'PUT',
          cache: 'no-store',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ status: item.status, note: item.note }),
        })
      )
    );
    
    setSuccessMessage('Status and notes updated successfully');
    setTimeout(() => {
      setSuccessMessage('');
      setLoadingStatus(false);
    }, 5000);

  } catch (error) {
    setErrorMessage('Failed to update status and notes');
    setTimeout(() => {
      setErrorMessage('');
      setLoadingStatus(false);
    }, 5000);
  }
};

const nameMapping = {
  'PO Installation Material': 'PO Installation Material',
  'PO Measurement Tools': 'PO Measurement Tools',
  'TRAINING': 'TRAINING',
  'ACCEPTANCE by BAKTI': 'ACCEPTANCE BY BAKTI',
  'INTEGRATION & INTERNAL APP': 'INTEGRATION & INTERNAL ATP',
  'BAST': 'BAST & INTEGRATION CERTIFICATE'
};

const getDisplayName = (originalName) => {
  return nameMapping[originalName] || originalName; // Return the mapped name or the original if not found
};

const getSectionTitle = (sectionKey) => {
  // Handle specific case for Cibitung
  if (sectionKey === 'ipHub' && cityName.toLowerCase() === 'cibitung') {
    return 'CNMS'; // Replace IP-HUB with CNMS if the city is Cibitung
  }

  // Define section titles mapping
  const sectionTitles = {
    ipHub: 'IP HUB',
    ipCore: 'IP CORE',
    sso: 'OSS',
    OTHERS: 'OTHERS',
  };

  // Return the title based on the section key, or default to uppercase
  return sectionTitles[sectionKey] || sectionKey.toUpperCase();
};


  if (loading) {
    return (
      <CenteredContainer>
        <CircularProgress sx={{ color: 'white', marginTop: '10px' }} />
        <p>Loading data for {cityName}...</p>
      </CenteredContainer>
    );
  }

  if (!cityData) {
    return (
      <CenteredContainer>
        <SentimentVeryDissatisfiedIcon style={{ fontSize: 60 }} />
        <p>No data available for {cityName}.</p>
      </CenteredContainer>
    );
  }

  const ipHubCoreOrder = ['PO', 'IMPORT', 'DELIVERY', 'INSTALLATION'];
  const otherOrder = ['PO Installation Material', 'PO Measurement Tools', 'TRAINING', 'INTEGRATION & INTERNAL ATP', 'ACCEPTANCE BY BAKTI', 'BAST & INTEGRATION CERTIFICATE'];

  const sortDataByOrder = (data, order) => {
    return data.sort((a, b) => {
      const aIndex = order.indexOf(a.progress_name);
      const bIndex = order.indexOf(b.progress_name);
      return (aIndex === -1 ? order.length : aIndex) - (bIndex === -1 ? order.length : bIndex);
    });
  };

  return (
    <div className="round">
      {successMessage && (
        <Alert
          variant="filled"
          severity="success"
          onClose={() => setSuccessMessage('')}
          sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 9999 }}
        >
          <AlertTitle>Success</AlertTitle>
          {successMessage}
        </Alert>
      )}

      {errorMessage && (
        <Alert
          variant="filled"
          severity="error"
          onClose={() => setErrorMessage('')}
          sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 9999 }}
        >
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
        <h2 className='edit-title'>Edit Data {capitalizeFirstLetter(cityName)}</h2>
        <IconButton onClick={handleClose} style={{ position: 'absolute', right: 0, top: 0, color: 'white' }}>
          <CloseIcon />
        </IconButton>
      </div>

      <h2 className='status-title'>Status</h2>
      {statusData.map((item, index) => (
        <div key={index} className="status-item" style={{ marginBottom: '10px' }}>
          <Select
            value={item.status}
            onChange={(e) => handleStatusChange(index, e.target.value)}
            sx={{
              marginBottom: '10px',
              minWidth: isMobile ? '100%' : '200px',
              color: 'white',
              fontSize: { xs: '12px', sm: '14px', md: '16px' },
            }}
            fullWidth={isMobile}
          >
            <MenuItem value={1}>ON TRACK</MenuItem>
            <MenuItem value={2}>POTENTIALLY OFF TRACK</MenuItem>
            <MenuItem value={0}>OFF TRACK</MenuItem>
          </Select>

          {(item.status === 0 || item.status === 2) && (
            <ReactQuill
              value={item.note}
              onChange={(newNote) => handleNoteChange(index, newNote)}
              modules={modules}
              formats={formats}
              style={{ color: 'white', minHeight: '100px', marginBottom: '20px' }}
            />
          )}
        </div>
      ))}

      {loadingStatus ?
        <LoadingButton
          loading
          loadingPosition="start"
          startIcon={<SaveIcon style={{ color: 'white' }} />}
          variant="outlined"
          color="default"
          fullWidth
          sx={{
            color: 'white',
            borderColor: 'white',
            '& .MuiCircularProgress-root': {
              color: 'white',
            },
            '&:hover': {
              borderColor: 'white',
            },
            '&.MuiButton-outlined': {
              borderColor: 'white',
            },
            '&.Mui-disabled': {
              borderColor: 'white',
              color: 'white',
            },
          }}
        >
          Loading
        </LoadingButton>
        :
        <Button
          variant="contained"
          color="warning"
          onClick={handleSaveStatus}
          fullWidth
          style={{ backgroundColor: 'rgba(245, 124, 0, 1)', color: 'white', fontWeight: 'bold', marginTop: 50 }}
        >
          Save Status
        </Button>
      }

      <hr />

      {(cityName.toLowerCase() === 'cikarang' ? ['ipCore'] : ['ipHub', 'ipCore', 'sso', 'OTHERS']).map((section) => (
        <div key={section} style={{ marginBottom: '20px' }}>
          <h2 className='progress-title-edite' align="center">{getSectionTitle(section)}</h2>
          {sortDataByOrder(cityData[section], section === 'OTHERS' ? otherOrder : ipHubCoreOrder).map((item, index) => (
              <div key={index} style={{ marginBottom: '10px' }}>
                <h3 className='progress-title-edite'>{getDisplayName(item.progress_name)}</h3>
                {isMobile && (
                  <TextField
                    label="Due Date"
                    type="date"
                    value={item.due_date || ''}
                    onChange={(e) => handleDueDateChange(section, index, e.target.value)}
                    fullWidth={false}
                    margin="normal"
                    sx={{
                      width: '160px',
                      '@media (max-width: 768px)': {
                        width: '100%',
                      },
                    }}
                    InputLabelProps={{ shrink: true, style: { color: 'white' } }}
                    InputProps={{ style: { color: 'white', borderColor: 'white', textAlign: 'center' } }}
                  />
                )}

                <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <GradientSlider
                    value={item.progress}
                    onChange={(e, newValue) => handleSliderChange(section, index, newValue)}
                    valueLabelDisplay="auto"
                    step={1}
                    min={0}
                    max={100}
                    style={{ flex: '2' }}
                  />

                  <TextField
                    label="Progress"
                    type="text"
                    value={item.progress}
                    onChange={(e) => handleTextFieldChange(section, index, e)}
                    fullWidth={false}
                    margin="normal"
                    style={{ width: '80px' }}
                    InputLabelProps={{ style: { color: 'white' } }}
                    InputProps={{
                      style: { color: 'white', borderColor: 'white', textAlign: 'center' },
                      inputProps: { style: { textAlign: 'center' } },
                    }}
                  />
                  {!isMobile && (
                    <TextField
                      label="Due Date"
                      type="date"
                      value={item.due_date || ''}
                      onChange={(e) => handleDueDateChange(section, index, e.target.value)}
                      fullWidth={false}
                      margin="normal"
                      sx={{
                        width: '160px',
                        '@media (max-width: 768px)': {
                          width: '100%',
                        },
                      }}
                      InputLabelProps={{ shrink: true, style: { color: 'white' } }}
                      InputProps={{ style: { color: 'white', borderColor: 'white', textAlign: 'center' } }}
                    />
                  )}
                </div>
              </div>
            ))}

          <h3 className='progress-title-edite'>REMARKS {section === 'sso' ? 'OSS' : getSectionTitle(section)}</h3>
          <ReactQuill
            value={cityData.remarks[section].description}
            modules={modules}
            formats={formats}
            onChange={(newRemarks) => handleRemarksChange(section, newRemarks)}
            style={{ color: 'white', minHeight: '200px' }}
          />
          <br/>
          {loadingSection[section] ? (
            <CenteredContainer>
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<SaveIcon style={{ color: 'white' }} />}
                variant="outlined"
                color="default"
                fullWidth
                sx={{
                  color: 'white',
                  borderColor: 'white',
                  '& .MuiCircularProgress-root': {
                    color: 'white',
                  },
                  '&:hover': {
                    borderColor: 'white',
                  },
                  '&.MuiButton-outlined': {
                    borderColor: 'white',
                  },
                  '&.Mui-disabled': {
                    borderColor: 'white',
                    color: 'white',
                  },
                }}
              >
                Loading
              </LoadingButton>
            </CenteredContainer>
          ) : (
            <Button
              variant="contained"
              color="warning"
              onClick={() => handleSaveSection(section, section === 'OTHERS' ? 'other' : section)}
              fullWidth
              style={{ backgroundColor: 'rgba(245, 124, 0, 1)', color: 'white', fontWeight: 'bold' }}
            >
              Save {getSectionTitle(section)}
              
            </Button>
          )}
          <hr />
        </div>
      ))}
    </div>
  );
};

export default EditPage;
