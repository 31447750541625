import React, { useState, useEffect } from 'react';
import '../assets/ProgressBar.css';

const ProgressBar = ({ title, progress, dueDate, lastUpdate }) => {
  const [isMobile, setIsMobile] = useState(false);

  // This effect will run on mount and when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Check if the width is less than or equal to 768px
    };

    // Check on initial render
    handleResize();

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to determine the color gradient based on progress
  const getProgressColor = (progress) => {
    if (progress <= 30) {
      return 'red';
    } else if (progress <= 60) {
      return 'linear-gradient(to right, red, yellow)';
    } else {
      return 'linear-gradient(to right, red, yellow, green)';
    }
  };

  const formatDateTime = (date) => {
      const formattedDate = new Date(date);
      
      // Check if the date is valid
      if (isNaN(formattedDate.getTime())) {
          return 'Invalid Date';
      }

      // Extract hours and minutes and ensure two digits
      const hours = String(formattedDate.getHours()).padStart(2, '0');
      const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
      
      // Extract day, month, and year and ensure two digits for day and month
      const day = String(formattedDate.getDate()).padStart(2, '0');
      const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
      const year = formattedDate.getFullYear();  // Should always return 4 digits

      return `last: ${hours}:${minutes} ${day}/${month}/${year}`;
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-GB', options); // Example format: DD/MM/YYYY
  };



  return (
    <div>
      {/* Conditionally render headers for 'PO' and 'INTEGRATION & INTERNAL APP' */}
      {(title === 'PO' || title === 'Purchase Third Party Component' || title === 'PO Installation Material') && (
        <div className="progress-header-row">
          <span className="progress-header-title">Progress</span>
          <span className="due-date-header-title">Due / Last Update</span>
        </div>
      )}

      <div className="progress-row">
        {isMobile ? (
          <div className="progress-info-row">
            <h2 className="progress-title-top">{title}</h2>
            <span className="progress-percentage">{progress}%</span>
            <span className="progress-due-date">
            {formatDate(dueDate)}<br /><div className="progress-last-update small-text">{formatDateTime(lastUpdate)}</div>
            </span>
          </div>
        
        ) : (
          <h2 className="progress-title-top">{title}</h2>
        )}

        <div className="progress-bar">
          {/* Dynamically set the background of progress-fill based on progress value */}
          <div 
            className="progress-fill" 
            style={{ width: `${progress}%`, background: getProgressColor(progress) }}
          ></div>
        </div>

        {/* Conditional rendering based on screen size */}
        {isMobile ? (
          <></>
        ) : (
          <>
            <span className="progress-percentage">{progress}%</span>
            <div className="progress-due-last-update">
              <span className="progress-due-date">{formatDate(dueDate)}</span>
              <span className="progress-last-update">{formatDateTime(lastUpdate)}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
