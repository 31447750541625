// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tabs {
    background-color: #444;
    border-top-left-radius: 10px; /* Only top-left corner rounded */
    border-top-right-radius: 10px; /* Only top-right corner rounded */
    border-bottom-left-radius: 0; /* Bottom-left corner remains sharp */
    border-bottom-right-radius: 0; /* Bottom-right corner remains sharp */
    width: 100%;
  }
  
  /* Center tabs on larger screens */
  @media (min-width: 769px) {
    .MuiTabs-flexContainer {
      justify-content: center; /* Center the tabs on larger screens */
    }
  }
  
  /* Adjust tabs for scrollable behavior on smaller screens */
  @media (max-width: 768px) {
    .custom-tabs {
      overflow-x: auto; /* Enable scrolling on smaller screens */
      justify-content: flex-start; /* Align tabs to the left */
    }
  }
  
  .MuiTab-root {
    color: white;
  }
  
  .MuiTab-root.Mui-selected {
    color: yellow;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/assets/TabsNavigation.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,4BAA4B,EAAE,iCAAiC;IAC/D,6BAA6B,EAAE,kCAAkC;IACjE,4BAA4B,EAAE,qCAAqC;IACnE,6BAA6B,EAAE,sCAAsC;IACrE,WAAW;EACb;;EAEA,kCAAkC;EAClC;IACE;MACE,uBAAuB,EAAE,sCAAsC;IACjE;EACF;;EAEA,2DAA2D;EAC3D;IACE;MACE,gBAAgB,EAAE,wCAAwC;MAC1D,2BAA2B,EAAE,2BAA2B;IAC1D;EACF;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,aAAa;EACf","sourcesContent":[".custom-tabs {\n    background-color: #444;\n    border-top-left-radius: 10px; /* Only top-left corner rounded */\n    border-top-right-radius: 10px; /* Only top-right corner rounded */\n    border-bottom-left-radius: 0; /* Bottom-left corner remains sharp */\n    border-bottom-right-radius: 0; /* Bottom-right corner remains sharp */\n    width: 100%;\n  }\n  \n  /* Center tabs on larger screens */\n  @media (min-width: 769px) {\n    .MuiTabs-flexContainer {\n      justify-content: center; /* Center the tabs on larger screens */\n    }\n  }\n  \n  /* Adjust tabs for scrollable behavior on smaller screens */\n  @media (max-width: 768px) {\n    .custom-tabs {\n      overflow-x: auto; /* Enable scrolling on smaller screens */\n      justify-content: flex-start; /* Align tabs to the left */\n    }\n  }\n  \n  .MuiTab-root {\n    color: white;\n  }\n  \n  .MuiTab-root.Mui-selected {\n    color: yellow;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
