import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, Alert, AlertTitle } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CloseIcon from '@mui/icons-material/Close'; // Import cancel/close icon
import LoadingButton from '@mui/lab/LoadingButton'; // Assuming you are using MUI Lab for loading button
import SaveIcon from '@mui/icons-material/Save';
import ReactQuill , { Quill }  from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS
import { useNavigate } from 'react-router-dom';
import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize);

const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      [{ color: [] }, { background: [] }], // This is for text color and background color
      ['clean'],  // Remove formatting button
    ],
    clipboard: {
        matchVisual: false
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
      }
  };
  
  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color', 'background', // Add color and background here
  ];

const GeneralPage = () => {
  const [content, setContent] = useState(''); // State to hold the remarks content
  const [loading, setLoading] = useState(true); // State to manage loading
  const [isEditMode, setIsEditMode] = useState(false); // State to manage edit mode
  const [open, setOpen] = useState(false); // Modal open state
  const [code, setCode] = useState('');  // Code input state
  const [errorCode, setErrorCode] = useState(false); // State to handle incorrect access code
  const [errorMessage, setErrorMessage] = useState(''); // Error message for fetch failure
  const [isSaving, setIsSaving] = useState(false); // State to manage saving process
  const navigate = useNavigate();

  // Fetch remarks from API
  useEffect(() => {
    const fetchRemarks = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://executive.api.bignet.id/api/remark/gateway/6/category/1'); // Fetch remarks from the API
        const data = await response.json();

        if (response.ok && data.status) {
          console.log("API Data: ", data.data[0].Description); // Log API response for debugging
          setContent(data.data[0].Description); // Set remarks content from API
        } else {
          throw new Error(data.message || 'Failed to fetch remarks');
        }
      } catch (error) {
        setErrorMessage(error.message); // Set error message if fetching fails
      } finally {
        setLoading(false); // Set loading to false after fetch completes
      }
    };

    fetchRemarks();
  }, []);

  const handleEditClick = () => {
    const accessCode = localStorage.getItem('accessCode');
    const accessTime = localStorage.getItem('accessTime');

    // Validate the existence of accessCode and accessTime
    if (accessCode && accessTime) {
      setIsEditMode(true); // Enter edit mode directly
    } else {
      setOpen(true); // Show dialog to input access code
    }
  };

  const handleCancelClick = () => {
    setIsEditMode(false); // Exit edit mode
  };

  const handleSave = async () => {
    setIsSaving(true); // Set saving state
    try {
      // Send the updated content via PUT request
      const response = await fetch('https://executive.api.bignet.id/api/remark/21', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          description: content, // Send the updated content
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to save the content');
      }

      const result = await response.json();
      console.log('Save success:', result);

      // Show success message or handle successful save
      alert('Content saved successfully!');
      setIsEditMode(false); // Exit edit mode after saving
    } catch (error) {
      console.error('Error saving content:', error);
      alert(`Failed to save content: ${error.message}`);
    } finally {
      setIsSaving(false); // End saving state
    }
  };

  if (loading) {
    // Show loading spinner while fetching data
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress style={{ color: 'white' }} />
      </div>
    );
  }

  if (errorMessage) {
    // Show error message if fetching fails
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column', textAlign: 'center' }}>
        <Alert severity="error">{errorMessage}</Alert>
      </div>
    );
  }

  return (
    <div style={{ padding: '20px', textAlign: 'center', backgroundColor: '#333' }}>
      <div className="city-header">
        <h1 className="city-title">General</h1>

        {/* Conditional rendering of IconButton */}
        <IconButton onClick={isEditMode ? handleCancelClick : handleEditClick}>
          {isEditMode ? (
            <CloseIcon className="edit-icon" /> // Show cancel icon if in edit mode
          ) : (
            <EditNoteIcon className="edit-icon" /> // Show edit icon if not in edit mode
          )}
        </IconButton>
      </div>
      <h3 className='progress-title' style={{ textAlign: 'left' }}>Remark</h3> 
      {/* Remarks content or editor */}
      <div style={{ margin: '20px 0', marginLeft: 'auto', marginRight: 'auto' }}>
        {isEditMode ? (
          // Show editor in edit mode
          <ReactQuill
            value={content}
            onChange={setContent}
            modules={modules} // Use the updated modules with color options
            formats={formats} // Ensure color and background formats are applied
            style={{
                maxHeight: '400px',
                minHeight: '200px', // Corrected minHeight value
                overflowY: 'auto',
                padding: '10px',
                borderRadius: '5px',
                color: 'black',
            }}
            />

        ) : (
          // Show remarks content in normal mode
          <div
            className="remarks-textarea"
            dangerouslySetInnerHTML={{ __html: content }}
            style={{
                maxHeight: '400px',
                minHeight: '200px',
                overflowY: 'auto',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                backgroundColor: '#333',
                color: 'white',
                textAlign: 'left', // Tambahkan ini untuk membuat teks rata kiri
            }}
            />

        )}
      </div>

      {/* Show save button only in edit mode */}
      {isEditMode && (
        <LoadingButton
          loading={isSaving} // Show loading state while saving
          loadingPosition="start"
          variant="contained"
          color="warning"
          onClick={handleSave}
          fullWidth
          style={{ backgroundColor: 'rgba(245, 124, 0, 1)', color: 'white', fontWeight: 'bold' }}
        >
          {isSaving ? 'Saving...' : 'Save Remarks'}
        </LoadingButton>
      )}

      {/* Access Code Modal */}
      <>
        {errorCode && (
          <Alert
            variant="filled"
            severity="error"
            onClose={() => setErrorCode(false)}
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 9999,
            }}
          >
            <AlertTitle>Error</AlertTitle>
            Incorrect code, please try again.
          </Alert>
        )}

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          maxWidth="sm"
          fullWidth
          sx={{ '& .MuiDialog-paper': { width: '400px', maxWidth: 'none' } }}
        >
          <DialogTitle sx={{ backgroundColor: '#333' }}>Enter Access Code</DialogTitle>
          <DialogContent sx={{ backgroundColor: '#333' }}>
            <TextField
              autoFocus
              margin="dense"
              label="Code"
              type="password"
              fullWidth
              variant="standard"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              InputLabelProps={{
                style: { color: 'white' },
              }}
              InputProps={{
                style: { color: 'white' },
              }}
              sx={{
                '& .MuiInput-underline:before': {
                  borderBottomColor: 'white',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: 'white',
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                  borderBottomColor: 'white',
                },
              }}
            />
          </DialogContent>
          <DialogActions sx={{ backgroundColor: '#333' }}>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'rgba(255, 0, 0, 0.2)', color: 'red', fontWeight: 'bold' }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'rgba(255, 255, 0, 0.2)', color: 'yellow', fontWeight: 'bold' }}
              onClick={() => {
                if (code === 'RpGuDJLhM3Hn7vxw6P8S2BqAa9VJ-BDI') {
                  localStorage.setItem('accessCode', 'RzzGudDJjLLhM3H97vxXw6P81iIlS2BqAa9VJ-Joko');
                  localStorage.setItem('accessTime', Date.now());
                  setOpen(false);
                  setIsEditMode(true); // Enter edit mode after successful code input
                } else {
                  setErrorCode(true);
                }
              }}
            >
              Enter
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </div>
  );
};

export default GeneralPage;
